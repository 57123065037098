<template>
  <PageWrapper>
    <PagePanel>
      <p class="title">
        {{ $t('menu.rebateTransfer') }}
      </p>
      <div class="transfer_fund">
        <el-form :model="transferForm" :rules="rules" label-position="top" ref="transferForm">
          <el-form-item :label="$t('common.keys.rebateAccount')" prop="rebateAccount">
            <el-select v-model="transferForm.rebateAccount" @change="rebateAccountChange" data-testid="rebateAccount" style="width: 100%;">
              <el-option v-for="item in rebates" :key="item.login"
                :label="item.login + ' - ' + item.balance + ' ' + item.currency" :value="item.login"
                :data-testid="item.login">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('common.keys.amount')" prop="amount">
            <numeric-input ref="numberInput" v-model="transferForm.amount" :showCurrency="true"
              :showCurrencySymbol="selectedRebateAccountCurrency | currencySymbol"
              :currency="selectedRebateAccountCurrency" :precision="2"></numeric-input>
          </el-form-item>
          <el-form-item :label="$t('transfer.account')" prop="tradingAccount">
            <el-select v-model="transferForm.tradingAccount" data-testid="tradingAccount" style="width: 100%;">
              <el-option v-for="item in tradingAccountList" :key="item.login"
                :label="item.login | accountListFormatter(item, transferTranslationObj)" :value="item.login"
                :data-testid="item.login">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div class="btn_wrapper">
              <el-button type="primary" @click="submitForm('transferForm')" data-testid="submitTransferForm">
                {{ $t('common.keys.SUBMIT') }}
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </PagePanel>
  </PageWrapper>
</template>

<script>
import { integerOrMaxTwoDecimalNumbersCreator } from '@/util/validators';
import { apiToApplyTransferView, apiApplyTransfer } from '@/resource';
import NumericInput from '@/components/NumericInput';
import mixin from '@/mixins';
import blackList from '@/mixins/blackList';

export default {
  components: { NumericInput },
  mixins: [mixin, blackList],
  data() {
    return {
      //对话框
      accountList: [],
      tradingAccountList: [],
      rebates: [],
      transferForm: {
        rebateAccount: '',
        amount: '',
        tradingAccount: ''
      },
      selectedRebateAccountCurrency: '',
      availableBalance: '0',
      transferTranslationObj: {
        1: this.$t('transfer.tradingAccount'),
        3: this.$t('transfer.rebateAccount')
      },
      rules: {
        rebateAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t('transfer.PleaseEnterTransferInformation'),

            trigger: 'blur'
          },
          {
            validator: integerOrMaxTwoDecimalNumbersCreator(this.$t('withdraw.CannotUseMoreThanTwoDecimalValues')),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value == 0) {
                callback(new Error(this.$t('withdraw.Itcannotbe0')));
              } else if (value > this.availableBalance) {
                callback(new Error(this.$t('transfer.transferAmountIsGreaterThanAvailableBalance')));
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        tradingAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ]
      },
      userId: this.$store.state.common.CUID,
      prevRoute: null
    };
  },
  //get previous path
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  filters: {
    accountListFormatter: function (value, item, transferTranslationObj) {
      if (value === undefined || value === null) {
        return '';
      } else {
        const currency = item.currency || ' ';
        const accountType =
          item.accountDealType === 1 || item.accountDealType === 3 ? transferTranslationObj[item.accountDealType] : '';
        value = item.login + ' - ' + currency + (accountType ? ' (' + accountType + ')' : '');
      }
      return value;
    }
  },
  methods: {
    //form表单提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) return false;
        else if (!this.checkRebateTransferBlacklist()) {
          this.transferErrMsg();
        } else this.applyTransfer();
      });
    },
    getAccountInfo() {
      apiToApplyTransferView({
        qUserId: this.userId
      }).then(resp => {
        if (resp.data.code === 0) {
          const respData = resp.data.data;
          this.accountList = respData.accountList;
          this.rebates = respData.rebates;
          this.rebates.forEach(element => {
            element.balance = element.balance.toFixed(2);
          });
          // 默认选中首页选中的账号
          if (this.rebates && this.rebates.length > 0) {
            this.transferForm.rebateAccount = this.rebates[0].login;
            this.selectedRebateAccountCurrency = this.rebates[0].currency;
          }
          this.rebateAccountChange(this.transferForm.rebateAccount);
        }
      });
    },
    // 佣金账号改变
    rebateAccountChange(val) {
      const [availableBalance] = this.rebates.filter(item => {
        return item.login === val;
      });
      if (availableBalance == undefined) {
        return;
      }
      this.availableBalance = availableBalance.balance;
      this.selectedRebateAccountCurrency = availableBalance.currency;
      let selectionList = this.rebates.concat(this.accountList).filter(item => {
        return item.login !== val;
      });
      this.tradingAccountList = selectionList.filter(a => {
        return a.currency == availableBalance.currency;
      });
      this.transferForm.tradingAccount = this.tradingAccountList.length > 0 ? this.tradingAccountList[0].login : null;
    },
    applyTransfer() {
      apiApplyTransfer(
        {
          qUserId: this.userId,
          fromAccount: this.transferForm.rebateAccount,
          toAccount: this.transferForm.tradingAccount,
          amount: this.transferForm.amount,
          transferType:
            this.tradingAccountList.find(item => item.login === this.transferForm.tradingAccount).accountDealType === 1
              ? 3
              : 4
        },
        this.token
      ).then(resp => {
        if (resp.data.code === 0) {
          this.$message.success(this.$t('transfer.yourRebateTransferHasBeenProcessed'));
          this.bus.$emit('updateTransferHistory', true);
          this.$nextTick(function () {
            this.$router.push(this.prevRoute && this.prevRoute.path != '/' ? this.prevRoute.name : 'home');
          });
        }
      });
    },
    async checkRebateTransferBlacklist() {
      if (this.$route.params.passBlacklist) return true;
      else
        await this.getRebateTransferBlacklist().then(resp => {
          return resp;
        });
    }
  },
  mounted() {
    this.getAccountInfo();
  }
};
</script>

<style scoped lang="scss">
// @import '@/assets/css/pages/rebateTransfer.scss';
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $text-primary;
  margin-bottom: 16px;
}

.transfer_fund {
  margin: 0 auto;
  max-width: 392px;

  .btn_wrapper {
    display: flex;
    justify-content: center;
  }
}
</style>
